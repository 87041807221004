<template>
  <span>

    <!-- Search options -->
    <v-row dense>
      <v-col cols="5">
          <v-text-field
              v-model="keyword"
              append-icon="mdi-magnify"
              label="Alias nimi, agentin sukunimi tai koodi"
              single-line
              hide-details
              clearable
              class="mb-0"
          ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-checkbox
            v-model="options.show_nonactive"
            label="näytä epäaktiiviset"
            class="pt-3 mb-0 "
        ></v-checkbox>
      </v-col>

      <v-col cols="1" v-if="api_route != 'audio_aliases'">
        <v-checkbox
            v-model="options.only_with_pictures"
            label="ainoastaan kuvalliset"
            class="pt-3 mb-0 "
        ></v-checkbox>
      </v-col>

      <v-col >
        <v-btn
            v-if="api_route == 'chat_aliases'"
            class="v-btn mt-3 mb-0 green float-right"
            :to="'/chat_alias/uusi/'+(agent_id ? agent_id : '')"
            small
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Lisää uusi chat-alias
        </v-btn>

        <v-btn
            v-if="api_route == 'audio_aliases'"
            class="v-btn mt-3 mb-0 green float-right"
            :to="'/audio_alias/uusi/'+(agent_id ? agent_id : '')"
            small
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Lisää uusi audio-alias
        </v-btn>

      </v-col>

    </v-row>

    <v-row dense>
      <v-col cols="2">
        <v-checkbox
            v-if="show_expand"
            v-model="expanded"
            label="laajenna kaikki"
            class="pt-0 mt-0 ml-4"
        >
        </v-checkbox>
      </v-col>
      <v-col cols="1" offset="3">
          <v-checkbox
              v-model="options.av"
              label="aikuisviihde"
              class="pt-0 mt-0"
          ></v-checkbox>
      </v-col>
      <v-col cols="1">
          <v-checkbox
              v-model="options.at"
              label="asiantuntija"
              class="pt-0 mt-0"
          ></v-checkbox>
      </v-col>
    </v-row>

    <!-- data table -->
    <v-data-table
        v-model="selected_aliases"
        :headers="headers"
        :items="models"
        :options.sync="options"
        :server-items-length="totalModels"
        :loading="loading"
        :sort-by="sort"
        :sort-desc="true"
        class="elevation-1"
        :show-expand="show_expand"
        :show-select="show_select"
        :expanded="expanded ? models : []"
        :item-class="itemClass"
        :footer-props="{
        'items-per-page-options':[
            15,
            30,
            50,
            100,
            200,
            ]
        }"
    >
      <!-- name slot (link) CHAT ALIAS -->
      <template slot="item.name" slot-scope="data">
        <router-link :to="'/chat_alias/'+data.item.id+'/main'">
          {{ data.item.name }}
        </router-link>
        <v-icon
            v-if="!data.item.active"
            color="red"
            title="ei aktiivisena"
            class="ml-2"
            @click="setActive(data.item)"
        >
          mdi-account-cancel
        </v-icon>

        <!-- add name here -->
        <b v-if="data.item.not_active_reason" class="red--text">
          {{ data.item.not_active_reason.name }}
        </b>

        <div class="description">{{ data.item.description }}</div>

        <!-- categories & subcategories -->
        <v-chip-group class="mb-8">
          <template v-if="data.item.categories">
            <v-chip
              x-small
              label
            >
              Kategorioissa:
            </v-chip>

            <v-chip
                v-for="cat in data.item.categories.slice(0,2)" :key="'cat'+cat.id"
                x-small
                outlined
            >
              {{ cat.name }}
            </v-chip>
            <v-chip
                v-if="data.item.categories.length > 2"
                x-small
                label
                class="green"
            >
              +{{ data.item.categories.length - 2 }}
            </v-chip>
          </template>

          <template v-if="data.item.sub_categories">
            <v-chip
                x-small
                label
            >
              Alikategoriat:
            </v-chip>

            <v-chip
                v-for="cat in data.item.sub_categories.slice(0,2)" :key="'cat'+cat.id"
                x-small
                outlined
            >
              {{ cat.name }}
            </v-chip>
            <v-chip
                v-if="data.item.sub_categories.length > 2"
                x-small
                label
                class="green"
            >
              +{{ data.item.sub_categories.length - 2 }}
            </v-chip>
          </template>
        </v-chip-group>

      </template>

      <!-- alias_name slot (link) AUDIO ALIAS-->
      <template slot="item.alias_name" slot-scope="data">
        <router-link :to="'/audio_alias/'+data.item.id+'/main'">
          {{ data.item.alias_name }}
        </router-link>
        <v-icon
            v-if="!data.item.active"
            color="red"
            title="ei aktiivisena"
            class="ml-2"
            @click="setActiveAudio(data.item)"
        >
          mdi-account-cancel
        </v-icon>

        <div class="description">{{ data.item.introduction }}</div>
        <div class="description" v-if="data.item.ads && data.item.ads[0]">{{ data.item.ads[0].introduction }}</div>

        <!-- attributes, categories, subcategories -->
        <v-chip-group class="mb-8">
          <template v-if="data.item.attributes">
            <v-chip
                x-small
                label
            >
              Attribuutit:
            </v-chip>

            <v-chip
                v-for="cat in data.item.attributes.slice(0,2)" :key="'att'+cat.id"
                x-small
                outlined
            >
              {{ cat.info }}
              <b v-if="!cat.alias_attribute && data.item.audio_files_missing" class="red--text ml-2">[Audio puuttuu!]</b>
            </v-chip>

            <v-chip
                v-if="data.item.attributes.length > 2"
                x-small
                label
                class="green"
            >
              +{{ data.item.attributes.length - 2 }}
            </v-chip>
          </template>

          <template v-if="data.item.categories">
            <v-chip
                v-if="data.item.categories.length > 0"
                x-small
                label
            >
              Kategoriat:
            </v-chip>

            <v-chip
                v-for="cat in data.item.categories.slice(0,2)" :key="'cat'+cat.id"
                x-small
                outlined
            >
              {{ cat.name }}
            </v-chip>

            <v-chip
                v-if="data.item.categories.length > 2"
                x-small
                label
                class="green"
            >
              +{{ data.item.categories.length - 2 }}
            </v-chip>
          </template>

          <template v-if="data.item.sub_categories">
            <v-chip
                v-if="data.item.sub_categories.length > 0"
                x-small
                label
            >
              Alakategoriat:
            </v-chip>

            <v-chip
                v-for="cat in data.item.sub_categories.slice(0,2)" :key="'cat'+cat.id"
                x-small
                outlined
            >
              {{ cat.name }}
            </v-chip>

            <v-chip
                v-if="data.item.sub_categories.length > 2"
                x-small
                label
                class="green"
            >
              +{{ data.item.sub_categories.length - 2 }}
            </v-chip>
          </template>

        </v-chip-group>

      </template>

      <!-- domains: paratiisi.fi, ennustajat.fi  -->
      <template slot="item.domains" slot-scope="data">
        <!-- loop ads -->
        <template v-for="ad in data.item.ads">
          <!-- loop domains -->
          <template v-for="dom in ad.domains">
            <template v-if="dom.domain_idDomain == 1">Paratiisi.fi</template>
            <template v-if="dom.domain_idDomain == 4">Ennustajat.fi</template>
          </template>
        </template>
      </template>

      <template slot="item.audio_alias_media" slot-scope="data">
        <template v-if="data.item.alias_media && data.item.alias_media.length > 0">
          <router-link :to="'/audio_alias/'+data.item.id+'/ads'">
            <v-img
                :src="audio_alias_advertise_picture_base+data.item.alias_media[0].filename"
                max-width="100"
            ></v-img>
          </router-link>
        </template>
        <!--
        <template v-if="data.item.ads">
          mainoksia: {{ data.item.ads.length }}
        </template>
        -->
      </template>

      <!-- alias_media (profile picture) -->
      <template slot="item.alias_media" slot-scope="data">
        <template v-if="data.item.alias_media && data.item.alias_media.length > 0">
          <router-link :to="'/chat_alias/'+data.item.id+'/media'">
            <v-img
                :src="chat_alias_profile_picture_base+data.item.alias_media[0].filename"
                max-width="100"
            ></v-img>
          </router-link>
        </template>
        <span v-if="data.item.premium_picture_count > 0">kuvia: {{ data.item.premium_picture_count }}</span>
        <span v-if="data.item.premium_video_count > 0" class="ml-1">
            videoita: {{ data.item.premium_video_count }}
        </span>
      </template>

      <!-- type (adult, professional) -->
      <template slot="item.type" slot-scope="data">
        <v-subheader v-if="data.item.type == 1" class="pink--text">AV</v-subheader>
        <v-subheader v-if="data.item.type == 2" class="cyan--text">AT</v-subheader>
      </template>

      <!-- agent -->
      <template slot="item.agent" slot-scope="data">
        <router-link v-if="data.item.agent" :to="'/agent/'+data.item.agent.agent_id+'/main'">
          {{ data.item.agent.code }} ({{ data.item.agent.firstname }})
        </router-link>
      </template>

      <!-- anline (chat_alias) -->
      <template slot="item.online" slot-scope="data">
        <v-subheader v-if="data.item.online" class="green--text">ON</v-subheader>
        <v-subheader v-else class="cyan--text">EI</v-subheader>
      </template>

      <!-- remove button -->
      <template slot="item.remove" slot-scope="data">
        <v-btn class="v-btn red" @click="remove(data.item)">poista agentilta</v-btn>
      </template>

      <!-- add button -->
      <template slot="item.add" slot-scope="data">
        <v-btn class="v-btn green" @click="add(data.item)">lisää agentille</v-btn>
      </template>

      <!-- expand slot if needed -->
      <template v-slot:expanded-item="{ headers, item }">

        <!-- merge all cells -->
        <td :colspan="headers.length" class="grey darken-3 text-left">

          <!-- introduction -->
          <div v-if="item.introduction">
            Alias intro: {{ item.introduction }}
          </div>

          <!-- loop ads -->
          <template v-if="item.ads">
            <template v-for="ad in item.ads">
              <!-- loop domains -->
              <template v-for="dom in ad.domains">
                <span :key="'domain'+dom.domain_idDomain">

                  <div v-if="dom.domain_idDomain == 1">Domain: Paratiisi.fi</div>
                  <div v-if="dom.domain_idDomain == 4">Domain: Ennustajat.fi</div>

                  <div v-if="dom.number">
                    Numero: {{ dom.number.number }}
                  </div>
                  <div v-if="dom.credio_number">
                    Credio-numero: {{ dom.credio_number.number }}
                  </div>
                </span>
              </template>
            </template>
          </template>

          <!-- description -->
          <div v-if="item.description">{{ item.description }}</div>

          <!-- attributes -->
          <v-chip-group
              v-if="item.attributes && item.attributes.length > 0"
          >
            <v-chip

                x-small
                label
            >
              Attribuutit:
            </v-chip>

            <v-chip
                v-for="cat in item.attributes" :key="'att'+cat.id"
                x-small
                outlined
            >
              {{ cat.info }}
              <b v-if="!cat.alias_attribute && item.audio_files_missing" class="red--text ml-2">[P]</b>
            </v-chip>

          </v-chip-group>

          <!-- categories -->
          <v-chip-group
              v-if="item.categories && item.categories.length > 0"
          >

            <v-chip
                x-small
                label
            >
              Kategorioissa:
            </v-chip>

            <v-chip
                v-for="cat in item.categories" :key="'cat'+cat.id"
                x-small
                outlined
            >
              {{ cat.name }}
            </v-chip>
          </v-chip-group>

          <!-- subcategories -->
          <v-chip-group v-if="item.sub_categories && item.sub_categories.length > 0" class="mb-8">
            <v-chip
                x-small
                label
            >
              Alikategoriat:
            </v-chip>

            <v-chip
                v-for="cat in item.sub_categories" :key="'cat'+cat.id"
                x-small
                outlined
            >
              {{ cat.name }}
            </v-chip>
          </v-chip-group>

          </td>
      </template>

      <!-- append selection options -->
      <template slot="body.append" slot-scope="{ headers }" v-if="has_selected">
        <td :colspan="headers.length" class="green darken-4 text-center pa-5">
          Valitut:
          <v-btn class="btn info ml-3" @click="dialog_transfer = true">Siirrä toiselle agentille</v-btn>
          <v-btn class="btn info ml-3" @click="dialog_remove_alert = true">Poista agentilta</v-btn>
          <v-btn class="btn info ml-3" @click="dialog_remove_completely_alert = true">Poista alias</v-btn>
        </td>
      </template>

      <!-- prepend selection options -->
      <template slot="body.prepend" slot-scope="{ headers }" v-if="has_selected">
        <td :colspan="headers.length" class="green darken-4 text-center pa-5">
          Valitut:
          <template v-if="selectionOptions == 'aliases'">
            <v-btn class="btn info ml-3" @click="dialog_category = true">määritä kategoria</v-btn>
          </template>
          <template v-else>
            <v-btn class="btn info ml-3" @click="dialog_transfer = true">Siirrä toiselle agentille</v-btn>
            <v-btn class="btn info ml-3" @click="dialog_remove_alert = true">Poista agentilta</v-btn>
            <v-btn class="btn info ml-3" @click="dialog_remove_completely_alert = true">Poista alias</v-btn>
          </template>
        </td>
      </template>

    </v-data-table>

    <v-snackbar
        v-model="snackbar"
        :timeout="snackbar_timeout"
        color="green"
    >
      {{ snackbar_message }}
    </v-snackbar>

    <v-dialog
      v-model="dialog_category"
      max-width="500px"
      >
        <v-card>
          <v-card-title>
            Määritä valituille aliaksille kategoria
          </v-card-title>
          <v-card-text>

            <v-autocomplete
                v-model="target_category_id"
                label="Hae nimellä kategoriaa"
                item-text="name"
                item-value="id"
                class="mt-5"
                :search-input.sync="searchInputs['target_category']"
                :items="searchItems['target_category']"
                :loading="searchLoading['target_category']"
                no-data-text="etsi kategoria"
            >
              <!-- disable selection, using external selection system -->
              <template v-slot:selection="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>

            <v-btn
              @click="transferCategories"
              :disabled="!target_category_id"
              >
              Siirrä {{ selected_count }} aliasta
            </v-btn>

          </v-card-actions>
        </v-card>

    </v-dialog>

    <v-dialog
          v-model="dialog_transfer"
          max-width="500px"
          >
        <v-card>
          <v-card-title>
            Siirrä valitut aliakset toiselle agentille?
          </v-card-title>
          <v-card-text>

            <v-autocomplete
                    v-model="target_agent_id"
                    label="Hae nimellä tai koodilla agentti jolle nämä siirretään"
                    item-text="name"
                    item-value="id"
                    class="mt-5"
                    :search-input.sync="searchInputs['target_agent']"
                    :items="searchItems['target_agent']"
                    :loading="searchLoading['target_agent']"
                    no-data-text="etsi agentti"
            >
              <!-- disable selection, using external selection system -->
              <template v-slot:selection="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
          </v-card-text>
          <v-card-actions>

            <v-btn
                    @click="transferAliases"
                    :disabled="!target_agent_id"
            >
              Siirrä {{ selected_count }} aliasta
            </v-btn>

          </v-card-actions>
        </v-card>

    </v-dialog>

    <v-dialog
        v-model="dialog_remove_alert"
        type="info"
        max-width="500px"
    >

      <v-alert
          type="error"
      >
        Poistetaanko valitut aliakset tältä agentilta?
        <v-spacer></v-spacer>

        <v-btn
            color="red"
            class="darken-2"
            @click="removeAliases"
        >
          Poista {{ selected_count }} aliasta
        </v-btn>

        <v-btn
            class="ml-4"
            @click="dialog_remove_alert = false"
        >
          Ei
        </v-btn>
      </v-alert>
    </v-dialog>

    <v-dialog
        v-model="dialog_remove_completely_alert"
        type="info"
        max-width="500px"
    >

      <v-alert
          type="error"
      >
        Poistetaanko valitut aliakset kokonaan
        <br/>
        (muutos tietokantaan: active=0, deleted_at=NOW)?
        <v-spacer></v-spacer>

        <v-btn
            color="red"
            class="darken-2"
            @click="deleteAliases"
        >
          Poista {{ selected_count }} aliasta
        </v-btn>

        <v-btn
            class="ml-4"
            @click="dialog_remove_completely_alert = false"
        >
          Ei
        </v-btn>
      </v-alert>
    </v-dialog>
  </span>

</template>

<script>
import GlobalVars from "../mixins/GlobalVars";

export default {
  mixins: [GlobalVars], //import default set of values
  props: [
      'api_route',
      'agent_id', //agent_id if required
      'aliases_type', //audio_aliases or chat_aliases
      'sort', //defaults sort
      'api_attributes', //predefined attributes required to get the list
      'headers_prop', //if want to change headers because of the button
      'show_expand', //if want list to be expandable
      'show_select', //if want list to be selectable
      'refresh_models', //if want to refresh dataset
      'selectionOptions' //if want to change selection options
  ],
  name: "AliasesTable",
  data() {
    return {
      totalModels: 0,
      options: {
        av: 1,
        at: 1,
      },
      headers_default: [ //header settings of the datatable
        {
          text: 'Nimi',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Kuva', value: 'alias_media', sortable: false },
        { text: 'Agent', value: 'agent', sortable: true },
        { text: 'Tyyppi', value: 'type', sortable: true },
        { text: 'Viest.', value: 'chat_message_count', sortable: true },
        { text: 'Online', value: 'online', sortable: true },
        { text: 'Luotu', value: 'created_at', sortable: true },
      ],
      selected_aliases: [],
      snackbar: false,
      snackbar_timeout: 5000,
      snackbar_message: null,
      expanded: false,
      dialog_transfer: false,
      dialog_remove_alert: false,
      dialog_remove_completely_alert: false,
      dialog_category: false,
      target_agent_id: null,
      target_category_id: null,
      searchInputs: {
        target_agent: "",
        target_category: "",
      },
      searchLoading: {
        target_agent: false,
          target_category: false,
      },
      searchItems: {
        target_agent: null,
        target_category: null,
      }
    }
  },
  watch: {

    id(){
      console.log('id chat aliases table')
      this.mount()
    },

    refresh_models(){
      this.models = []
      this.mount()
    },

    "searchInputs.target_agent"() {

      if (this.searchInputs.target_agent && this.searchInputs.target_agent != "") {
        clearTimeout(this.search_timeout)
        this.search_timeout = setTimeout(() => {
          this.getSearchItems('target_agent', 'agents', this.searchInputs.target_agent, '&active=1')
        }, 500)
      }
    },

      "searchInputs.target_category"() {

          if (this.searchInputs.target_category && this.searchInputs.target_category != "") {
              clearTimeout(this.search_timeout)
              this.search_timeout = setTimeout(() => {
                  this.getSearchItems('target_category', 'categories', this.searchInputs.target_category)
              }, 500)
          }
      }

  },
  computed: {

    has_selected(){

      if(Object.keys(this.selected_aliases).length > 0)
        return true
        else
        return false

    },

    selected_count(){

      return Object.keys(this.selected_aliases).length

    },

    headers(){

      if(this.headers_prop) return this.headers_prop
      else return this.headers_default

    },

    apiParams(){
      var arr = {
        skip: (this.options.page * this.options.itemsPerPage - this.options.itemsPerPage),
        take: this.options.itemsPerPage,
        order: this.apiOrder,
        order_direction: (this.options.sortDesc[0] ? 'DESC' : 'ASC'),
        keyword: this.keyword,
        deleted_at: 'NULL',
        active: this.options.show_nonactive ? false : true, //active: true, if not set, show all
        type: this.paramsType,
        filter_product_type_id1: this.options.only_with_pictures ? 18 : null, //active: true, if not set, show all
      }

      return Object.assign( {}, arr, this.api_attributes );
    },

    paramsType(){

      if(this.options.at && this.options.av) return null
      if(this.options.av) return 1
      if(this.options.at) return 2

      return null

    },

    apiOrder(){

      //use started as default if sortBy is empty
      return this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'created_at'

    },

  },
  methods: {

    setActive(item){

      this.axios.post('/agents/'+item.agent.agent_id+'/chat_alias_activation/', {aliases: [item.id]}).then(()=>{

        this.snackbar_message = "alias päivitetty aktiiviseksi"
        this.snackbar = true

        this.models.filter(x=>x.id==item.id)[0].active = true
        this.models.filter(x=>x.id==item.id)[0].not_active_reason = null
        this.models.filter(x=>x.id==item.id)[0].not_active_reason_id = null

        this.$root.$emit('aliases_table', this.models.filter(x => x.id == item.id)[0])
      })

    },

    setActiveAudio(item){

      this.axios.post('/agents/'+item.agent_id+'/audio_alias_activation/', {aliases: [item.id]}).then(()=>{

        this.snackbar_message = "audio alias päivitetty aktiiviseksi"
        this.snackbar = true

        this.models.filter(x=>x.id==item.id)[0].active = true
        this.models.filter(x=>x.id==item.id)[0].not_active_reason = null
        this.models.filter(x=>x.id==item.id)[0].not_active_reason_id = null

        this.$root.$emit('aliases_table', this.models.filter(x => x.id == item.id)[0])
      })

    },

    itemClass(item){

      if(item.not_active_reason_id == 1) return 'not_active_reason_1'
      if(item.not_active_reason_id == 2) return 'not_active_reason_2'

      return false

    },

    transferAliases(){

      this.axios.post('/agents/'+this.target_agent_id+'/chat_alias_transfer', {
        target_agent_id: this.target_agent_id,
        aliases: this.selected_aliases.map(item => {return item.id})
      }).then(() => {

        this.removeFromModels(this.selected_aliases)
        this.snackbar = true
        this.snackbar_message = "Alias / aliakset siirretty toiselle agentille"
        this.selected_aliases = []
        this.dialog_transfer = false

      })

    },

    transferCategories(){
          this.axios.post('/chat_aliases/category', {
              target_category_id: this.target_category_id,
              aliases: this.selected_aliases.map(item => {return item.id})
          }).then(() => {
              this.snackbar = true
              this.snackbar_message = "Aliasekset laitettiin valitsemaasi kategoriaan"
              this.selected_aliases = []
              this.dialog_transfer = false

          })
      },

    removeAliases(){

      this.axios.post('/agents/'+this.agent_id+'/chat_alias_remove_multi', {
          aliases: this.selected_aliases.map(item => {return item.id})
      }).then(() => {

        this.removeFromModels(this.selected_aliases)
        this.snackbar = true
        this.snackbar_message = "Alias / aliakset poistettu agentilta"
        this.selected_aliases = []
        this.dialog_remove_alert = false

      })

    },

    deleteAliases(){

      this.axios.post('/chat_aliases/delete/multi', {
        aliases: this.selected_aliases.map(item => {return item.id})
      }).then(() => {

        this.removeFromModels(this.selected_aliases)
        this.snackbar = true
        this.snackbar_message = "Alias / aliakset poistettu"
        this.selected_aliases = []
        this.dialog_remove_alert = false

      })

    },

    removeFromModels(selected_aliases){

      selected_aliases.map(item => {return item.id}).forEach(el => {
        this.models.splice(this.models.findIndex(x => x.id == el), 1)
      })

    },

    add(item){

      console.log('add', item)

      //api call

      //remove from array
      this.models.splice(this.models.findIndex(x => x.id == item.id), 1)

      //snackbar
      this.snackbar_message = item.name+' lisättiin aliaksiin'
      this.snackbar = true

    },

    remove(item){

      console.log('remove', item)

      //api call

      //remove from array
      this.models.splice(this.models.findIndex(x => x.id == item.id), 1)

      //snackbar
      this.snackbar_message = item.name+' poistettiin aliaksista'
      this.snackbar = true


    },

    mount(){

      if(this.loading) return //silently fail if loading is already ongoing

      this.loading = true //enable loading sign

      //get models
      this.axios.get(this.api_route, {params:
        this.apiParams
      })
      .then((response)=>{
        this.models = response.data
        this.loading = false //disable loading sign
      }).catch(e => {
        console.log(e)
        this.models = []
        this.axiosError(e)
      });

      //also get model count
      this.axios.get(this.api_route+'/count', {params:
        this.apiParams
      }).then((response)=>{
        this.totalModels = response.data
      })

    }

  },
  mounted(){

    this.mount()

    //only update chat_aliases activity
    this.$root.$on('aliases_table_chat_aliases', function(payload){

      if(this.aliases_type != 'chat_aliases'){
        console.log('on aliases_table_chat_aliases type mismatch', this.aliases_type)
        return
      }

      //if chat alias id is found in array, replace agent with payload.
      if(this.models.some(item => item.id == payload.id))
        { this.models = this.models.map(item => item.id == payload.id ? item = payload : item) }
        else
          if(!this.agent_id) this.models.unshift(payload) //if no agent id is set, add new item at the beginning of an array
        else
          if(this.agent_id && this.agent_id == payload.agent_id) this.models.unshift(payload) //if agent_id is set and payload.agent_id matches with agent_id, add new item at the beginning of an array

    }.bind(this))

    //only update alias profile picture
    this.$root.$on('alias_profile_picture', function(payload){

      console.log('mounted chat_aliases', payload[0])

      //if agent id is found in array, replace agent with payload. (any downside to this?)
      if(this.models.some(item => item.id == payload[0]))
      {
        console.log('mounted alias_profile_picture', payload)
        //this.models = this.models.map(item => item.id == payload[0] ? item.alias_media[0] = payload[1] : item)
        this.models[this.models.findIndex(x => x.id == payload[0])].alias_media = [payload[1]]
      }

    }.bind(this))

    //only update chat_aliases activity
    this.$root.$on('aliases_delete', function(payload){
      console.log('mounted aliases_delete')
      //if agent id is found in array, replace agent with payload. (any downside to this?)
      this.models.splice(this.models.findIndex(x => x.id == payload), 1)

    }.bind(this))

    //aliases_table_audio_aliases
    //delete, alias_profile_picture

  }

}
</script>

<style scoped>
  .description {
    font-size: 0.9em;
    max-width:600px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>