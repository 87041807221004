<template>
  <span>

    <!-- Heading -->
    <h1 class="headline white--text">
      <v-icon
          color="green"
          title="päivitä"
          class="ml-2"
          style="cursor: pointer"
          @click="ts = Date()"
      >
        mdi-refresh-circle
      </v-icon>
      Chat-aliakset</h1>

    <aliases-table
        api_route="chat_aliases"
        aliases_type="chat_aliases"
        sort="created_at"
        :refresh_models="ts"
        :show_expand="true"
        :show_select="true"
        selectionOptions="aliases"
    ></aliases-table>

  </span>
</template>

<script>
// @ is an alias to /srcm
import GlobalVars from "../mixins/GlobalVars";
import AliasesTable from "../components/AliasesTable";

export default {
  name: 'ChatAliases',
  mixins: [GlobalVars], //import default set of values
  data() {
    return {
      models: [],
      order: 'id',
      order_direction: 'asc',
      expanded: false,
      ts: '',
    }
  },
  components: {AliasesTable},
}
</script>
<style>
  .not_active_reason_1 {
    background-color: #623333;
  }
  .not_active_reason_2 {
    background-color: #884747;
  }
</style>
